import * as HttpService from './http.service';
import { USER_SESSION } from './url.service';
import { CREATE_USER } from './url.service';
import { GET_USERS_URL } from './url.service';
import { GET_SINGLE_USER_URL } from './url.service';
import { UPDATE_ADMIN_USER_URL } from './url.service';

export const userSession = (mobile, otp) => {
  return HttpService.postWithOutAuth(USER_SESSION, { mobile, otp });
};

export const createUser = (userId, userDetails) => {
  return HttpService.postWithAuth(CREATE_USER(userId), { ...userDetails });
};

export const getUsers = (adminId, token, offset, limit, search) => {
  return HttpService.getWithAuth(
    GET_USERS_URL(adminId, offset, limit, search),
    token
  );
};

export const getSingleUser = (userId, id, token) => {
  return HttpService.getWithAuth(GET_SINGLE_USER_URL(userId, id), token);
};
export const updateAdminUser = (userId, id, userDetails) => {
  return HttpService.putWithAuth(UPDATE_ADMIN_USER_URL(userId, id), { ...userDetails });
};

export const deleteAdminUser = (userId, id) => {
  return HttpService.deleteWithAuth(GET_SINGLE_USER_URL(userId, id));
};