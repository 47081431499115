import React, { useState } from 'react'
import Login from '@/components/Onboarding/Login'
import Head from 'next/head'
import { setAuth } from 'services/identity.service'
import { systemAdminLogin } from 'services/system-admin.service'
import { userSession } from 'services/user.service'
import { useRouter } from 'next/router'

const LoginPage = () => {
  const router = useRouter()

  const [showOtpInput, setShowOtpInput] = useState(false)
  const [error, setError] = useState('')

  const handleLogin = async (mobile) => {
    const res = await systemAdminLogin(mobile)
    if (res.data.status) {
      setShowOtpInput(true)
      setError('')
    } else {
      setError(res.data.message)
    }
  }

  const handleVerification = async (mobile, otp) => {
    const res = await userSession(mobile, otp)
    if (res.data.status) {
      let roleName = res.data.entity.roles[0]
      setAuth(res.data.entity)
      setError('')
      router.replace(roleName == 'System Admin' ? '/dashboard' : '/')
    } else {
      setError(res.data.message)
    }
  }

  return (
    <>
      <Head>
        <title>Combo@CO Login</title>
      </Head>
      <Login
        handleLogin={handleLogin}
        handleVerification={handleVerification}
        showOtpInput={showOtpInput}
        error={error}
        userType="System Admin"
      />
    </>
  )
}

export default LoginPage
