import { Alert } from 'reactstrap';
  
const Toast = (props) => {
  const toastColors = {
    error: 'danger',
    success: 'success',
    warning: 'warning',
    primary: 'primary' 
  };

  return(
    <Alert color={toastColors[props.type]} role="alert">
      {props.msg.map((err, idx) => {
        return(<ul key={idx}>
          <li>{err}</li>
        </ul>);
      })}
    </Alert>
  );
};

export default Toast;