import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import AuthCode from "react-auth-code-input";
import Toast from "@/components/UI/Toast";

const Login = (props) => {
  const [enterdMobile, setMobile] = useState();
  const [enteredOtp, setOtp] = useState();
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!props.showOtpInput) {
      props.handleLogin(enterdMobile);
    }

    if (props.showOtpInput) {
      props.handleVerification(enterdMobile, enteredOtp);
    }
  };

  return (
    <Container>
      <Row
        className="justify-content-center"
        style={{ height: "100vh", alignItems: "center" }}>
        <Col md={8} lg={6} xl={5}>
          {!props.showOtpInput && (
            <Card>
              <CardBody>
                {props.error && <Toast msg={props.error} type={"error"} />}
                <div className="p-2">
                  <div className="text-center">
                    <div className="p-2 mt-4">
                      <h4>{props.userType} Login</h4>
                      <p className="mb-5">Please enter your mobile number</p>
                      <Form onSubmit={submitHandler}>
                        <Row>
                          <Col xs={12}>
                            <input
                              type="number"
                              className="form-control"
                              id="floatingmobileInput"
                              placeholder="Enter Mobile"
                              onChange={handleMobileChange}
                            />
                          </Col>
                        </Row>
                      </Form>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary w-sm"
                          onClick={submitHandler}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}

          {props.showOtpInput && (
            <Card>
              <CardBody>
                {props.error && <Toast msg={props.error} type={"error"} />}
                <div className="p-2">
                  <div className="text-center">
                    <div className="p-2 mt-4">
                      <h4>Verify Mobile</h4>
                      <p className="mb-5">
                        Please enter the 4 digit code sent to{" "}
                        <span className="font-weight-semibold">
                          {enterdMobile}
                        </span>
                      </p>

                      <Form onSubmit={submitHandler}>
                        <Row>
                          <Col xs={12}>
                            <FormGroup className="verification">
                              <label htmlFor="digit1-input" className="sr-only">
                                Dight 1
                              </label>
                              <AuthCode
                                characters={4}
                                className="form-control form-control-lg text-center"
                                allowedCharacters="^[0-9]"
                                inputStyle={{
                                  width: "76px",
                                  height: "42px",
                                  padding: "8px",
                                  borderRadius: "8px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                  marginRight: "15px",
                                  border: "1px solid #ced4da",
                                  textTransform: "uppercase",
                                }}
                                onChange={(entered_otp) => setOtp(entered_otp)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-sm"
                            onClick={submitHandler}>
                            Confirm OTP
                          </button>
                        </div>
                      </Form>
                      {/* <div className="mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary w-sm"
                          onClick={submitHandler}>
                          Confirm OTP
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
          <div className="text-center">
            {props.showOtpInput && (
              <div>
                Did&apos;t receive a code ?{" "}
                <a
                  href="#"
                  className={`fw-medium text-primary ${
                    counter > 0 ? "disabled" : "null"
                  }`}
                  onClick={() => {
                    props.handleLogin(enterdMobile);
                    setCounter(60);
                  }}>
                  {" "}
                  Resend{" "}
                </a>{" "}
                {counter > 0 && <p>Resend OTP in {counter}s</p>}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
